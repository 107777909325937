
import Infos from "../components/index/Infos.vue";
import Hello from "../components/index/Hello.vue";
import InfosTwo from "../components/index/InfosTwo.vue";
import Marque from "../components/index/Marque.vue";
import Ask from "../components/index/Ask.vue";
import Connected from "../components/index/Connected.vue";

export default {
  name: "HomePage",
  components: {
    Hello,
    Infos,
    InfosTwo,
    Marque,
    Ask,
    Connected,
  },
  mounted() {
    document.querySelectorAll(".accordion-header").forEach((button) => {
      button.addEventListener("click", () => {
        const accordionContent = button.nextElementSibling;

        button.classList.toggle("active");

        if (button.classList.contains("active")) {
          accordionContent.style.maxHeight =
            accordionContent.scrollHeight + "px";
        } else {
          accordionContent.style.maxHeight = 0;
        }
        document
          .querySelectorAll(".accordion-header")
          .forEach((otherButton) => {
            if (otherButton !== button) {
              otherButton.classList.remove("active");
              otherButton.nextElementSibling.style.maxHeight = 0;
            }
          });
      });
    });
  },
};
