import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f6ed444 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _5734a624 = () => interopDefault(import('..\\pages\\price.vue' /* webpackChunkName: "pages/price" */))
const _e9363a04 = () => interopDefault(import('..\\pages\\RedeemCode.vue' /* webpackChunkName: "pages/RedeemCode" */))
const _50a0ae18 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _9591e0e2 = () => interopDefault(import('..\\pages\\updatePwd.vue' /* webpackChunkName: "pages/updatePwd" */))
const _b4719da6 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _6f6ed444,
    name: "login"
  }, {
    path: "/price",
    component: _5734a624,
    name: "price"
  }, {
    path: "/RedeemCode",
    component: _e9363a04,
    name: "RedeemCode"
  }, {
    path: "/register",
    component: _50a0ae18,
    name: "register"
  }, {
    path: "/updatePwd",
    component: _9591e0e2,
    name: "updatePwd"
  }, {
    path: "/",
    component: _b4719da6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
